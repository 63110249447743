* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Catamaran", sans-serif;
}
/* General classes */
:root {
	--font-smallest: 0.8rem;
	--font-small: 1rem;
	--font-medium: 1.2rem;
	--font-big: 1.4rem;
	--font-biggest: 1.6rem;
}
body {
	background-color: #00172d;
	background: linear-gradient(
		90deg,
		rgba(0, 23, 45, 1) 51%,
		rgba(0, 11, 45, 1) 94%,
		rgba(26, 0, 45, 1) 99%
	);
}
h1,
h2,
h3,
h4,
h5,
p {
	color: #dbdde9;
}
hr {
	color: #dbdde9;
	height: 4px;
}
a {
	text-decoration: none;
}
.fix-header-a {
	display: block;
	position: relative;
	top: -90px;
	visibility: hidden;
}
@media (min-width: 992px) {
	.fix-header-a {
		top: -150px;
	}
}
