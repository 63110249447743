.contact__mainImageContainer {
	height: 250px;
	color: white;
	text-align: center;
	background-image: url("../Assets/Background-pictures/752x506-Dark-Blue-Kitchens.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.contact__logo_inner {
	display: none;
	width: 210px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.contact__form_main_container {
	position: relative;
}
.contact__title {
	margin: 20px 0;
	text-align: center;
}
.contact__info {
	font-size: large;
	padding: 0 1rem;
	width: 70%;
	margin: 30px auto;
}
.contact__container__instructionsPicture {
	position: relative;
	margin-bottom: 60px;
}
.contact__image__instructions {
	top: 0;
	position: absolute;
	display: block;
	width: 100%;
	height: 300px;
	margin: auto;
	object-fit: cover;
}
.contact__instructions__container > div {
	border: 1px solid #dbdde9;
	padding: 10px 20px;
	box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
		rgb(60 64 67 / 15%) 0px 2px 6px 2px;
}
.contact__instructions__container {
	padding: 20px 0;
	background-color: #00172d;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 290px;
	margin: 0 auto;
	top: 20px;
	position: relative;
	box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
		rgb(60 64 67 / 15%) 0px 2px 6px 2px;
}
.contact__instructions__title {
	margin-bottom: 30px;
}
.contact__instructions__info {
}
.contact__instructions__info > span {
	font-weight: 600;
	font-style: italic;
}
.contact__form__inner > h2 {
	font-style: oblique;
}
.contact__form__inner {
	box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
		rgb(60 64 67 / 15%) 0px 2px 6px 2px;

	display: flex;
	margin: 20px 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 350px;
	margin: 0 auto 50px auto;
	padding: 15px 0;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
}
.contact__form__input__container {
	display: flex;
	justify-content: space-between;
	width: 300px;
	margin-bottom: 15px;
}
.contact__form__label {
	color: #dbdde9;
}

.contact__form__input {
	width: 65%;
}

.contact__form__btn {
	color: #00172d;
	background-color: #dbdde9;
	padding: 5px 20px;
	font-weight: 600;
}
.label-required {
	color: red;
	font-size: small;
	font-style: italic;
}
.label-display-none {
	display: none;
}
.hideLogo {
	display: none !important;
}
.center-form {
	margin: 40px auto !important;
}
.form-control-sm {
	font-size: 1rem;
}
.preloaderContactForm {
	position: relative;

	background: rgba(0, 23, 45, 0.97);
}
.preloaderContactForm::before {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-image: url("../Assets/preloader/1494.gif");
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto;
	width: 100%;
	height: 100%;
	content: "";
	background-color: rgba(0, 23, 45, 0.97);
}
.indexMinus {
	z-index: -1;
}
.Contact__responseContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 90%;
	background-color: #dbdde9;
	bottom: -1px;
	left: 5%;
	transform: translateY(100%);
	border-radius: 20px;
	box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
		rgb(60 64 67 / 15%) 0px 2px 6px 2px;
	transition: 1s;
}
.Contact__responseContainer > h2 {
	padding: 15px 0;
	margin-bottom: 0;
	color: #00172d;
}
.Contact__responseContainer > svg {
	font-size: calc(1.325rem + 0.9vw);
	fill: #00172d;
	font-weight: 600;
	margin-left: 20px;
}
.make-response-visible {
	transform: translateY(-20%);
}
@media (min-width: 768px) {
	.contact__mainImageContainer {
		height: 350px;
	}
	.contact__image__instructions {
		height: 400px;
	}
	.contact__container__instructionsPicture {
		margin-bottom: 70px;
	}
}
@media (min-width: 992px) {
	.contact__form__btn {
		font-size: larger;
	}
	.contact__form__input__container {
		font-size: larger;
	}
	.contact__form__input__container > input,
	.contact__form__input__container > textarea {
		font-size: larger;
	}
	.contact__instructions__info {
		font-size: larger;
	}
	.contact__info {
		font-size: x-large;
	}
	.contact__container__instructionsPicture {
		margin-bottom: 0;
	}
	.contact__mainImageContainer {
		height: 450px;
	}
	.contact__image__instructions {
		position: relative;
	}
	.contact__instructions__container {
		background-color: initial;
		width: 310px;
		top: 0;
		float: right;
		margin: 10px 40px 40px 0;
	}
	.contact__form__inner {
		max-width: 370px;
		margin: 40px 0 130px 0;
		left: 44%;
		transform: translateX(-50%);
	}
	.contact__instructions__container > div {
		border: none;
		box-shadow: none;
	}
	.contact__logo_inner {
		display: initial;
		left: 25px;
		transform: translate(0, -50%);
	}
}
@media (min-width: 1063px) {
	.contact__form__inner {
		left: 45%;
	}
}
@media (min-width: 1168px) {
	.contact__form__inner {
		left: 47%;
	}
}
@media (min-width: 1200px) {
	.contact__form__inner {
		left: 50%;
	}
	.contact__logo_inner {
		width: 400px;
	}
}
@media (min-width: 1400px) {
	.contact__form__input {
		width: 60%;
	}
	.contact__form__inner {
		max-width: 485px;
	}
	.contact__form__input__container {
		width: 90%;
		font-size: x-large;
	}
	.contact__form__inner > h2 {
		margin-bottom: 30px;
	}
}
