.home__mainImageContainer {
	height: 250px;
	color: white;
	text-align: center;
	background-image: url("../Assets/RoyaltyFreePhotos/home__mainImage.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.home__aboutSection__title {
	margin-top: 20px;
	text-align: center;
}
.home__aboutSection__quote {
	text-align: center;
	font-size: small;
}
.home__aboutSection__description {
	text-align: center;
	padding: 0 10px;
}
.home__Services__title,
.home__Projects__title,
.Testimonials {
	text-align: center;
	margin: 20px 0;
}
.ServiceSingle__container {
	height: 200px;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.ServiceSingle__title {
	position: relative;
	top: 20px;
	left: 15px;
	cursor: pointer;
	padding: 10px 20px;
	border-radius: 20px;
	color: #dbdde9;
	background: linear-gradient(
		90deg,
		rgba(0, 23, 45, 1) 51%,
		rgba(0, 11, 45, 1) 94%,
		rgba(26, 0, 45, 1) 99%
	);
	width: fit-content;
	transition: 0.8s;
	font-weight: 700;
	font-style: italic;
	display: flex;
	align-items: center;
}

.ServiceSingle__title:hover {
	padding-right: 70px;
	color: #dbdde9;
	background-color: #1c2782;
}
.ServiceSingle__title:hover:after {
	position: absolute;
	right: 10px;
	top: 0;
	font-size: inherit;
	font-family: "Font Awesome 5 Pro";
	font-weight: 900;
	content: "\f101";
}
.home__readMoreServices,
.home__readMoreProjects {
	display: flex;
	margin: 20px auto;
	font-size: larger;
}
.carousel {
	height: auto;
	font-size: small;
	margin: 30px 0;
}
.home__testimonials__image {
	object-fit: cover;
	height: 250px;
}
.carousel-caption {
	width: 100%;
	left: 0;
	height: 100%;
	top: 0;

	box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
		rgb(60 64 67 / 15%) 0px 2px 6px 2px;
}
.carousel-caption > h3 {
	font-weight: 800;
}
.carousel-caption > p {
	padding: 0 15%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	margin-bottom: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
	background-color: #00172d;
	border-radius: 50%;
	background-size: 70% 70%;
}
.carousel-indicators [data-bs-target] {
	background-color: #00172d;
}
.home__aboutSection {
	margin: 30px 0;
}
.stars__container {
	color: #ffd700;
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
}
.home__servicesContainer-Projects > .ServiceSingle__container {
	margin-bottom: 20px;
}

@media (min-width: 768px) {
	.stars__container {
		color: #ffd700;
		position: static;
		bottom: 0;
		left: 50%;
		transform: none;
	}
	.ServiceSingle__title {
		left: 50%;
		transform: translateX(-50%);
	}
	.home__aboutSection__description {
		font-size: larger;
		padding: 0 30px;
	}
	.home__aboutSection {
		margin: 40px 0;
	}
	.carousel-control-next-icon,
	.carousel-control-prev-icon {
		width: 3rem;
		height: 3rem;
	}
	.carousel-caption > p {
		font-size: large;
		line-height: 2;
	}
	.home__mainImageContainer {
		height: 350px;
	}
	.ServiceSingle__container {
		height: 300px;
		border-top: 2px solid #00172d;
	}
	.carousel {
		height: 400px;
		font-size: larger;
		width: 90%;
		left: 5%;
	}
	.home__testimonials__image {
		height: 350px;
	}
	.home__readMoreServices,
	.home__readMoreProjects {
		font-size: x-large;
	}
}
@media (min-width: 992px) {
	.carousel-caption > p {
		font-size: larger;
	}
	.home__mainImageContainer {
		height: 450px;
	}
	.home__aboutSection__quote {
		font-size: medium;
	}
	.home__aboutSection__description {
		font-size: x-large;
	}
	.home__servicesContainer {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-evenly;
	}
	.ServiceSingle__container {
		width: 40%;
		height: 300px;
		margin-bottom: 40px;
	}
	.home__readMoreServices,
	.home__readMoreProjects {
		font-size: x-large;
		margin: 0 auto 20px auto;
	}
	.ServiceSingle__title {
		margin: 10px auto;
		left: 0;
		transform: translateX(0);
		text-decoration: none;
		font-weight: 500;
	}
	.ServiceSingle__title:hover {
		padding-right: 150px;
		color: #dbdde9;
		background-color: #1c2782;
	}
	.ServiceSingle__title:hover:after {
		top: 50%;
		transform: translateY(-50%);
	}
}
@media (min-width: 1400px) {
	.home__servicesContainer {
		width: 80%;
		left: 10%;
		position: relative;
	}
}
